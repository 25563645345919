import React from 'react';

import { Form, Row, InputGroup, Col } from 'react-bootstrap';
import { withTranslation, Trans } from 'react-i18next';

//import './sweatratecalculator.scss';

class SweatRateCalculator extends React.Component {
  constructor(props) {
    super();
    this.initComponent = this.initComponent.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = this.initComponent(props);
    console.log(this.state);
  }

  initComponent(props) {
    return {
      preweight: '',
      postweight: '',
      volumeconsumed: '',
      duration: '',
    }
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }


  render() {

    let { t } = this.props;

    let renderWeightloss = false;
    let renderSweatrate = false;

    let weightloss, sweatrate, sweatrate_rounded;

    if (!isNaN(this.state.preweight) 
        && !isNaN(this.state.postweight) 
        && !isNaN(this.state.volumeconsumed)
        && Number(this.state.preweight) > 0
        && Number(this.state.postweight) > 0
        ) {
      weightloss = (Number(this.state.preweight) - Number(this.state.postweight)) + Number(this.state.volumeconsumed) / 1000;
      renderWeightloss = true;
      if (!isNaN(this.state.duration) && Number(this.state.duration) > 0) {
        sweatrate = weightloss / this.state.duration * 60;
        sweatrate_rounded =new Intl.NumberFormat().format(Math.round(sweatrate * 1000))
        renderSweatrate = true;
      }
    }

    return (
      <>
      <h1>{t('tools.sweatratecalculator.heading')}</h1>

      <Trans i18nKey="tools.sweatratecalculator.intro">
      <p>This online calculator allows you to find your sweat rate (fluid loss per hour) in a simple but effective way. Contrary to its title, it also takes fluid losses due to breathing into account. So we might have called it "Fluid Loss Rate Calculator", but this sounded catchier.</p>
      <p>Weigh yourself before and after your workout, and add any fluids you consumed during your session. Works best for workouts that are short enough so that you don't have to go to the loo during them.</p>
      </Trans>
      <Row>
      <Col md={6}>
      <h2>{t('tools.sweatratecalculator.your_data')}</h2>
      <Form>

        <Row>
          <Col>
          
        <Form.Group>
          <Form.Label>{t('tools.sweatratecalculator.label_preweight')}</Form.Label>
          <InputGroup>
          <Form.Control type="number" name="preweight" onChange={this.handleChange} value={this.state.preweight}/>
          <InputGroup.Text>kg</InputGroup.Text>
          </InputGroup>
          <Form.Text>{t('tools.sweatratecalculator.help_preweight')}</Form.Text>
        </Form.Group>
        </Col>
        <Col>
        <Form.Group>
          <Form.Label>{t('tools.sweatratecalculator.label_postweight')}</Form.Label>
          <InputGroup>
          <Form.Control type="number" name="postweight" onChange={this.handleChange} value={this.state.postweight}/>
          <InputGroup.Text>kg</InputGroup.Text>
          </InputGroup>
          <Form.Text>{t('tools.sweatratecalculator.help_postweight')}</Form.Text>
        </Form.Group>
        </Col>
        </Row>
        <Row>
          <Col>
        <Form.Group>
          <Form.Label>{t('tools.sweatratecalculator.label_volumeconsumed')}</Form.Label>
          <InputGroup>
          <Form.Control type="number" name="volumeconsumed" onChange={this.handleChange} value={this.state.volumeconsumed}/>
          <InputGroup.Text>ml</InputGroup.Text>
          </InputGroup>
          <Form.Text>{t('tools.sweatratecalculator.help_volumeconsumed')}.</Form.Text>
        </Form.Group>
        </Col>
        <Col>
        <Form.Group>
          <Form.Label>{t('tools.sweatratecalculator.label_duration')}</Form.Label>
          <InputGroup>
          <Form.Control type="number" name="duration" onChange={this.handleChange} value={this.state.duration}/>
          <InputGroup.Text>min</InputGroup.Text>
          </InputGroup>
          <Form.Text>{t('tools.sweatratecalculator.help_duration')}</Form.Text>
        </Form.Group>
        </Col>
        </Row>
      </Form>
      </Col>
      <Col md={5}>

      {renderWeightloss || renderSweatrate  ? 
        <h2 className="result">{t('tools.sweatratecalculator.results')}</h2>
        
      : null}

      { renderWeightloss ? 

      <div className="result-weightloss">{t('tools.sweatratecalculator.weightloss')}: {Math.round(weightloss*100)/100} kg</div>
        
      : null }

      {renderSweatrate ?
        <div className="sweatrate">
        <div className="result-sweatrate">{t('tools.sweatratecalculator.sweatrate')}: {new Intl.NumberFormat().format(Math.round(sweatrate * 1000))} {t('tools.sweatratecalculator.sweatrate_unit')}</div>
        <Trans i18nKey='tools.sweatratecalculator.results_explanation'>
        <p>To compensate for fluid loss due to sweat and respiratory exchange, aim to drink {{sweatrate_rounded}} ml per hour. If this is difficult or impossible, re-hydrate after exercise to get fully hydrated again.</p>
        <p>Your sweat rate can differ wildly depending on the type of exercise and the intensity. Repeat this calculation for different circumstances.</p>
        </Trans>
        </div>
        
      : null }

      


</Col>

</Row>

      </>

      


    )
  }

}
export default withTranslation()(SweatRateCalculator);
